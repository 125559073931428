import { cn } from "@/lib/utils";
import React, { SVGProps } from "react";

const LevelIcon = ({
  width,
  height,
  className,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 20"
      fill="none"
      className={cn(className)}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 18.9998H22" stroke="currentColor" strokeMiterlimit="10" />
      <path
        d="M1.51154 17.7522V15.2601C1.51154 15.2601 1.49788 14.9227 1.86453 14.9139C2.31589 14.9032 3.54736 14.9139 3.54736 14.9139C3.54736 14.9139 3.87572 14.9053 3.87572 15.2601C3.87572 15.6149 3.87572 17.7522 3.87572 17.7522"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M7.02643 17.753V12.6302C7.02643 12.6302 7.01277 12.2928 7.37942 12.2841C7.83078 12.2733 9.06225 12.2841 9.06225 12.2841C9.06225 12.2841 9.39061 12.2754 9.39061 12.6302C9.39061 12.985 9.39061 17.753 9.39061 17.753"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M12.5433 17.7524V9.79133C12.5433 9.79133 12.5296 9.45392 12.8963 9.4452C13.3476 9.43447 14.5791 9.4452 14.5791 9.4452C14.5791 9.4452 14.9075 9.43655 14.9075 9.79133C14.9075 10.1461 14.9075 17.7524 14.9075 17.7524"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M18.0601 17.753V6.46907C18.0601 6.46907 18.0464 6.13166 18.4131 6.12293C18.8645 6.1122 20.0959 6.12293 20.0959 6.12293C20.0959 6.12293 20.4243 6.11428 20.4243 6.46907C20.4243 6.82385 20.4243 17.753 20.4243 17.753"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M1.48901 11.9618C2.97759 11.5234 6.04223 10.808 11.296 7.73898C16.3399 4.79248 19.3736 1.76243 19.7457 1.37012"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M19.9422 2.54604L19.9859 1L18.3879 1.16151"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default LevelIcon;
