import { cn } from "@/lib/utils";
import React, { SVGProps } from "react";

const BookmarkIcon = ({
  width,
  height,
  className,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 24"
      fill="none"
      className={cn(className)}
      width={width}
      height={height}
      {...props}
    >
      <path
        d="M15.7994 24L7.89969 18.5831L0 24V0H15.7994V24ZM7.89969 16.7774L14.2947 21.1411V1.5047H1.5047V21.1411L7.89969 16.7774Z"
        fill="currentColor"
      />
      <path d="M0 0V24L8.25 18.5831L16.5 24V0H0Z" fill="currentColor" />
    </svg>
  );
};

export default BookmarkIcon;
