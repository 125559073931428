import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";
import { HTMLAttributes } from "react";

interface ChessTitleProps {
  playerTitle: string | null | undefined;
  size?: "default" | "small" | "large";
}

type chessTitleVariant =
  | "default"
  | "gm"
  | "im"
  | "fm"
  | "cm"
  | null
  | undefined;
const chessTitles = ["gm", "im", "fm", "cm"];

const ChessTitle: React.FC<
  HTMLAttributes<HTMLDivElement> & ChessTitleProps
> = ({ className, playerTitle, size = "default" }) => {
  let playerTitleClass;
  if (playerTitle === null || playerTitle === undefined) {
    playerTitle = "--";
    playerTitleClass = "default";
  }
  playerTitleClass = playerTitle?.toLowerCase();
  if (playerTitleClass.charAt(0) === "w") {
    playerTitleClass = playerTitleClass.slice(1);
  }
  if (!chessTitles.includes(playerTitleClass.toLowerCase())) {
    playerTitleClass = "default";
  }
  const playerTitleVariants = cva(
    "w-[50px] inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium select-none text-white text-base leading-none rounded py-1.5 px-2.5",
    {
      variants: {
        playerTitle: {
          gm: "bg-gm",
          wgm: "bg-gm",
          im: "bg-im",
          wim: "bg-im",
          fm: "bg-fm",
          wfm: "bg-fm",
          cm: "bg-cm",
          wcm: "bg-cm",
          default: "bg-gray-600",
        },
        size: {
          default: "",
          small: "text-2xs py-[2px] px-[5px] w-6",
          large: "py-2.5 px-6",
        },
      },
      defaultVariants: { playerTitle: "default", size: "default" },
    },
  );

  return (
    playerTitle && (
      <div
        className={cn(
          playerTitleVariants({
            playerTitle: playerTitleClass.toLowerCase() as chessTitleVariant,
            size,
            className,
          }),
        )}
      >
        {playerTitle?.toUpperCase()}
      </div>
    )
  );
};

export default ChessTitle;
