import { cn } from "@/lib/utils";
import React, { SVGProps } from "react";

const KnightIcon = ({
  width,
  height,
  fill,
  isSolid = false,
  className,
  ...props
}: SVGProps<SVGSVGElement> & { isSolid?: boolean }) => {
  return isSolid ? (
    <svg
      viewBox="0 0 22 21"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
      width={width}
      height={height}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0874 5.71977L10.1512 2.5C9.72613 3.25863 9.31407 4.59756 9.90649 5.56413C9.61895 5.5882 9.34416 5.63392 9.13161 5.71412C7.77556 6.22575 6.28764 7.5476 5.55633 10.2978C5.34457 11.0942 4.96344 11.4755 4.51046 11.9288C4.08317 12.3563 3.59194 12.8478 3.11863 13.812C2.14356 15.7983 4.77507 17.7845 6.69392 15.9805C7.77272 14.9662 8.7157 14.5475 9.5074 14.196C10.6469 13.69 11.473 13.3232 11.9396 11.5201C12.1616 12.3612 12.0728 14.346 9.94151 15.5572C7.8102 16.7683 7.36617 19.3194 7.41057 20.0764H19.5324C19.5324 12.8901 18.5205 6.68122 13.0874 5.71977Z"
        fill="currentColor"
      />
    </svg>
  ) : (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
      width={width}
      height={height}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2353 1.13752C9.876 0.718408 9.33244 0.504429 8.78383 0.566119C8.23521 0.627808 7.75275 0.95716 7.49546 1.44561C7.24024 1.93014 6.9902 2.59042 6.88891 3.31269C6.86328 3.49552 6.84578 3.69342 6.84247 3.90142C5.36008 4.73132 4.06656 6.34528 3.42626 8.90648C3.38138 9.08601 3.33164 9.17721 3.28683 9.24441C3.22376 9.33902 3.14257 9.4308 2.93472 9.65201C2.53031 10.0824 1.96427 10.685 1.44657 11.8067C0.65651 13.5185 1.35341 15.2576 2.59348 16.1405C3.32323 16.6601 4.22944 16.887 5.13237 16.7435C4.99097 17.3483 4.94197 17.8968 4.96373 18.2914C5.01245 19.1748 5.74305 19.8663 6.62787 19.8663H16.9876C17.9081 19.8663 18.6543 19.1201 18.6543 18.1996C18.6543 14.9085 18.4434 11.6287 17.5523 9.01468C16.6979 6.50831 15.1253 4.39503 12.3888 3.64918L10.2353 1.13752ZM2.95983 12.5052C3.36434 11.6287 3.78416 11.1819 4.14933 10.7933C4.53647 10.3813 4.86219 10.0346 5.04317 9.31071C5.51062 7.44091 6.33978 6.29742 7.21297 5.64516C7.50728 5.42532 7.80658 5.26128 8.09872 5.14404C8.28037 5.07114 8.51522 5.02958 8.76096 5.0077C8.62838 4.77761 8.55466 4.52432 8.5246 4.26336C8.48984 3.9616 8.51344 3.6496 8.57189 3.35141C8.65591 2.92272 8.81193 2.52255 8.97006 2.22235L11.4795 5.14918C15.7226 5.94783 16.8105 10.7298 16.9665 16.5329C16.9812 17.0802 16.9876 17.6366 16.9876 18.1996H6.62787C6.62464 18.1411 6.62443 18.0708 6.62787 17.9905C6.64268 17.6444 6.72521 17.1118 6.92586 16.5329C7.05604 16.1574 7.23593 15.7623 7.4793 15.386C7.79763 14.8939 8.22456 14.4338 8.79089 14.0915C8.84727 14.0575 8.90197 14.0227 8.95505 13.9873C10.6168 12.8799 10.6824 11.1627 10.4985 10.4218C10.0997 12.0608 9.39372 12.3943 8.41988 12.8542C8.32491 12.8991 8.22739 12.9451 8.12736 12.9937C7.56517 13.2668 6.92367 13.6198 6.20936 14.2886C6.14529 14.3486 6.08063 14.4111 6.01539 14.4764C4.37548 16.1163 2.1265 14.3107 2.95983 12.5052Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default KnightIcon;
