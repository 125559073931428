"use client";
import React, { FC, HTMLAttributes, useState } from "react";
import BookmarkOutlineIcon from "./svg-icons/bookmark-outline-icon";
import BookmarkIcon from "./svg-icons/bookmark-icon";
import { cn } from "@/lib/utils";

interface BookmarkButtonProps {
  handler: () => void;
  isBooked: boolean;
}

export default function BookmarkButton({
  handler,
  isBooked,
  className,
  ...props
}: HTMLAttributes<HTMLButtonElement> & BookmarkButtonProps) {
  const [isLoading, setIsLoading] = useState(false);

  async function handleBookmark() {
    setIsLoading(true);
    try {
      await handler();
    } catch (error) {
      console.log("Error bookmarking");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <button
      className={cn("group", className)}
      {...props}
      onClick={handleBookmark}
      disabled={isLoading}
    >
      {!isBooked ? (
        <div
          className="text-primary-focus group-hover:text-primary"
          title="Favorilere Ekle"
        >
          <BookmarkOutlineIcon width={32} height={32} />
        </div>
      ) : (
        <div
          className="group-hover:text-primary-focus text-primary"
          title="Favorilerden Çıkar"
        >
          <BookmarkIcon width={32} height={32} />
        </div>
      )}
    </button>
  );
}
