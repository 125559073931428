"use client";
import { StaticImport } from "next/dist/shared/lib/get-img-props";
import Image, { ImageProps } from "next/image";
import { useEffect, useState } from "react";

type ImageWithFallback = ImageProps & {
  fallback: string | StaticImport;
};

export default function ImageWithFallback({
  fallback,
  alt,
  src,
  ...props
}: ImageWithFallback) {
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [src]);

  return (
    <Image
      alt={alt}
      onError={() => setError(true)}
      src={error ? fallback : src}
      sizes={"auto"}
      {...props}
    />
  );
}
