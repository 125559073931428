"use client";

import { FC } from "react";
import KnightIcon from "./svg-icons/knight-icon";
import { decimalPlaces, getFormattedImageUrl } from "@/lib/utils";
import LevelIcon from "./svg-icons/level-icon";
import ChessTitle from "./chess-title";
import { useSession } from "next-auth/react";
import BookmarkButton from "./bookmark-button";
import Link from "next/link";
import { SeriesWithVideoTrainer } from "@/types";
import axios from "axios";
import ImageWithFallback from "./ui/image-with-fallback";

interface SeriesCardProps {
  series: SeriesWithVideoTrainer;
  type?: string;
}
const playerTextColors: Record<string, string> = {
  GM: "text-[#ba1717]",
  WGM: "text-[#ba1717]",
  IM: "text-[#3818d9]",
  WIM: "text-[#3818d9]",
  FM: "text-[#e9c200]",
  WFM: "text-[#e9c200]",
  CM: "text-[#cd6b2e]",
  WCM: "text-[#cd6b2e]",
};
const SeriesCard: FC<SeriesCardProps> = ({ series, type }) => {
  const { data: session, update: updateSession } = useSession();

  async function handleBookmark(seriesId: number) {
    if (!session?.user) return;

    const data = { seriesId: seriesId };
    const { data: ratingResponse } = await axios.post(
      "/api/series/bookmark",
      data,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    );

    const updateResult = await updateSession({
      user: {
        UserAccount: {
          UserSeries: ratingResponse,
        },
      },
    });
  }

  return (
    <div className="relative mx-auto flex w-full max-w-[380px] flex-col rounded-md bg-white shadow-sm">
      <Link
        href={`/seri/egitim/${series.Slug}`}
        className="text-decoration-none group flex h-full w-full select-none flex-col"
      >
        <ImageWithFallback
          src={`${
            series.ImageUrl ? getFormattedImageUrl(series.ImageUrl) : "/images/"
          }`}
          fallback="/images/series-ph.jpg"
          width={340}
          height={284}
          className="h-auto w-full rounded-t-md"
          alt={series.Title + " resmi"}
        />

        <div className="flex h-auto w-full flex-col justify-between p-2 group-hover:bg-neutral-100">
          <div className="flex h-[100px] flex-col">
            <div className="mb-1 h-auto text-[22px] font-normal leading-7">
              {series.Title}
            </div>
            <div className="mb-2 flex">
              <div className="mr-1 flex items-center">
                <ChessTitle
                  playerTitle={series.VideoTrainer.Title}
                  size="small"
                />
              </div>
              <div
                className={`flex items-center text-[16px] ${series.VideoTrainer.Title && playerTextColors[series.VideoTrainer.Title] ? `${playerTextColors[series.VideoTrainer.Title]}` : ""} font-light`}
              >
                <span>{series.VideoTrainer.FirstName} </span>
                <span>{series.VideoTrainer.Lastname}</span>
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center justify-between border-t border-[#C5C5C5] pt-1 text-neutral-600">
            <div className="flex items-center text-nowrap text-2xs">
              <LevelIcon width={22} height={18} className="mr-1" />
              <div>
                <span>Önerilen Seviye:</span>{" "}
                <span>
                  {series.SeriRatingMin} - {series.SeriRatingMax}
                </span>
              </div>
            </div>
            <div
              className="flex items-center text-nowrap text-2xs"
              title="Puanı"
            >
              <div className="italic">
                {decimalPlaces(series.averageRating, 2)}
              </div>

              <KnightIcon width={20} height={20} className="ml-1" />
            </div>
          </div>
        </div>
        {series.Isnew === 1 && (
          <div className="absolute left-0 top-2.5 rounded-br rounded-tr bg-success px-2.5 py-1 text-xs font-bold text-white shadow-sm">
            Yeni
          </div>
        )}
      </Link>

      {session?.user && (
        <BookmarkButton
          handler={async () => {
            await handleBookmark(series.Id);
          }}
          isBooked={session?.user?.UserAccount?.UserSeries?.map(
            (s) => s.SerieId,
          ).includes(series.Id)}
          className="absolute right-2 top-2"
        />
      )}
    </div>
  );
};

export default SeriesCard;
